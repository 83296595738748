import { useState, useEffect } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import BaseModal from "./BaseModal";
import GoogleRecaptcha from "~/components/forms/GoogleRecaptcha";
import { Form, Input, Button } from "antd";
import { MobileOutlined } from "@ant-design/icons";

import { bindByPhone } from "~/redux/actions/user";
import { showAppModal, showAppMessage } from "~/redux/actions/app";
import { onInputChange } from "~/utils/form";

const PhoneBindModal = (props) => {
  const [state, setState] = useState({
    phone: "",
    code: "",
  });

  const handleInputChange = onInputChange({ state, setState });
  const handleBindSubmit = () => {
    props.bindByPhone(props.token, {
      phone: `+86${state.phone}`,
      auth_code: state.code,
    });
  };

  useEffect(() => {
    if (props.user.phone) {
      props.hideModal();
      props.showAppMessage("绑定成功", "success");
    }
  }, [props.user]);

  return (
    <BaseModal isOpen={props.isOpen} hideModal={props.hideModal} showLogo>
      <Form onFinish={handleBindSubmit}>
        <Form.Item>
          <Input
            name="phone"
            placeholder="手机号码"
            prefix={<MobileOutlined />}
            value={state.phone}
            onChange={handleInputChange}
          />
        </Form.Item>

        <GoogleRecaptcha
          code={state.code}
          phone={state.phone}
          onChange={handleInputChange}
        />

        <div className="mb-20">
          <Button type="primary" htmlType="submit" block>
            立即绑定
          </Button>
        </div>
      </Form>

      <style jsx global>
        {`
          .modal-logo {
            display: block;
            margin: 20px auto;
            height: 38px;
          }

          .form-group {
            padding: 10px;
            display: flex;
          }

          .form-group-policy {
            color: #f9ba48;
            font-size: 14px;
          }

          .form-control {
            flex: 1;
            font-size: 16px;
            line-height: 22px;
            padding: 11px 16px;
            border-radius: 2px;
            border: 1px solid rgba(216, 216, 216, 1);
          }
        `}
      </style>
    </BaseModal>
  );
};

const mapStateToProps = (state) => ({
  request: state.app.request,
  token: state.user.token,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      bindByPhone,
      showAppModal,
      showAppMessage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PhoneBindModal);
