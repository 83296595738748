import { useCallback, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  sendAuthCode,
  refreshAuthCodeText,
  SEND_AUTH_CODE,
} from "../../redux/actions/user";

import { Form, Input, Button, Row, Col } from "antd";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const AuthCodeFormItem = (props) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("login");
    props.sendAuthCode({
      phone: `+86${props.phone}`,
      captcha: token,
    });
  }, [executeRecaptcha, props.phone]);

  useEffect(() => {
    const timer = setInterval(() => {
      props.refreshAuthCodeText();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const codeDisabled = () => {
    return props.request[SEND_AUTH_CODE] || props.codeRemains > 0;
  };

  const codeButtonText = () => {
    return props.codeRemains > 0
      ? `${props.codeRemains}秒后重试`
      : "获取验证码";
  };

  return (
    <Form.Item>
      <Row gutter={8}>
        <Col xs={10} sm={14}>
          <Input
            placeholder="验证码"
            maxLength={6}
            name="code"
            value={props.code}
            onChange={props.onChange}
          />
        </Col>
        <Col xs={14} sm={10}>
          <Button
            type="primary"
            onClick={handleReCaptchaVerify}
            disabled={codeDisabled()}
            block
          >
            {codeButtonText()}
          </Button>
        </Col>
      </Row>
    </Form.Item>
  );
};

const mapStateToProps = (state) => ({
  codeRemains: state.user.codeRemains,
  request: state.app.request,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendAuthCode,
      refreshAuthCodeText,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AuthCodeFormItem);
