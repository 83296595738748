import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import settings from "~/settings";
import AuthCodeFormItem from "./AuthCodeFormItem";

const GoogleRecaptcha = (props: any) => {
  return (
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={settings.recaptchaSiteKey}
      language="zh-CN"
    >
      <AuthCodeFormItem {...props} />
    </GoogleReCaptchaProvider>
  );
};

export default GoogleRecaptcha;
